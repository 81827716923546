import moment from 'moment';
import "daterangepicker";

export function initDatePicker(dateRangePicker, rentalBeginDate, rentalEndDate) {

    console.log("inside the datepicker module");

    let today = moment();
    let tomorrow = moment().add(1, 'days');

    function callback(startDate, endDate, label) {
        $(rentalBeginDate).val(startDate.format('YYYY-MM-DD'));
        $(rentalEndDate).val(endDate.format('YYYY-MM-DD'));

        //console.log("selection made");
        var diff = endDate.diff(startDate, 'days') + 1;
        //console.log(diff);
        //console.log(typeof diff);

        var dropOffEarlier = $("#dropOffEarlier");
        var dropOffLater = $("#dropOffLater");

        if (diff > 30) {
            dropOffLater.prop("checked", false);
            dropOffLater.attr("disabled", true);

        } else {
            dropOffLater.removeAttr("disabled");
        }

        dropOffEarlier.prop("checked", true);

    }

    $(dateRangePicker).daterangepicker({
        //opens: 'left'
        //autoUpdateInput: false,
        startDate: today,
        endDate: tomorrow,
        minDate: today,
        locale: {
            format: "MMM D, YYYY"
        },
        buttonClasses: "button is-small has-text-weight-bold",
        applyButtonClasses: "is-danger",
        cancelButtonClasses: "is-light",

    }, callback);

    callback(today, tomorrow, undefined);


    //$(dateRangePicker).on('showCalendar.daterangepicker', function (ev, picker) {

    //    console.log("show Calender...");

    //    //var el = $("tr").has("td.off.ends:not(.disabled)");
    //    var el = $("tr").has("td.off.ends.available");

    //    el.each(function () {                                
    //        var children = $(this).children("td.off.ends");

    //        if (children.length == 7) {
    //            $(this).remove();
    //        } else {
    //            children.css("visibility", "hidden");
    //        }
    //    });                            
    //});



}