﻿export function initDestinationCountryDropown(el, callback) {
    var sel = el;
    sel.append($("<option>").attr('value', "").text("Select a Country"));

    $.ajax({
        type: "GET",
        url: "/api/countries",
        cache: false,
        contentType: "application/json; charset=utf-8",
        complete: function () {

        },
        success: function (data) {
            console.log(data);
            var usa = data.find(x => x.code === "USA");
            var canada = data.find(x => x.code === "CAN");
            //var mexico = data.find(x => x.code === "MEX");

            sel.append($("<option>").attr('value', usa.code).text(usa.name));
            sel.append($("<option>").attr('value', canada.code).text(canada.name));
            //sel.append($("<option>").attr('value', mexico.code).text(mexico.name));
            //sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("—————————————"));

            //$(data).each(function () {
            //    sel.append($("<option>").attr('value', this.code).text(this.name));
            //});

            //sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("—————————————"));
            //sel.append($("<option>").attr('value', "_ZZ").text("Country Not Listed Here"));

            callback(sel, data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log("error - fetching countries");
        }
    });
}

export function initResidenceCountryDropown(el, callback) {
    var sel = el;
    sel.append($("<option>").attr('value', "").text("Select a Country"));

    $.ajax({
        type: "GET",
        url: "/api/countries",
        cache: false,
        contentType: "application/json; charset=utf-8",
        complete: function () {

        },
        success: function (data) {
            console.log(data);
            var usa = data.find(x => x.code === "USA");
            var canada = data.find(x => x.code === "CAN"); //azure cdn downloads the file upto this location everytime. might be some invisible and incompatible character is the problem
            var mexico = data.find(x => x.code === "MEX");

            sel.append($("<option>").attr('value', usa.code).text(usa.name));
            sel.append($("<option>").attr('value', canada.code).text(canada.name));
            sel.append($("<option>").attr('value', mexico.code).text(mexico.name));
            sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("-------------"));

            $(data).each(function () {
                sel.append($("<option>").attr('value', this.code).text(this.name));
            });

            //sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("-------------"));
            //sel.append($("<option>").attr('value', "_ZZ").text("Country Not Listed Here"));

            callback(sel, data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log("error - fetching countries");
        }
    });
}


