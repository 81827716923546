﻿import "jquery-validation";
import "jquery-validation-unobtrusive";

export function initValidator() {

    var validator = $("form").data('validator');

    validator.settings.errorPlacement = function (error, element) {
        var ul = $("#messageBox1 ul");

        $("<li>").html(error).appendTo(ul);
    };

    validator.settings.showErrors = function (errorMap, errorList) {

        var messageBox = $("#messageBox1");

        if (this.numberOfInvalids() == 0) {
            messageBox.hide();
        }
        else {
            messageBox.show();
        }
        this.defaultShowErrors();
    }
}

