﻿

export function initStatesDropdown(countrycode, el, callback) {
    console.log(countrycode);

    if (countrycode) {
        var sel = el;
        sel.empty();
        sel.append($("<option>").attr('value', "").text("Select a State"));

        $.ajax({
            type: "GET",
            url: "/api/countries/states/" + countrycode,
            cache: false,
            contentType: "application/json; charset=utf-8",
            complete: function () {

            },
            success: function (data) {
                console.log(data);
                $(data).each(function () {
                    sel.append($("<option>").attr('value', this.code).text(this.name));
                });

                //sel.append($("<option>").attr({ 'value': "__@", "disabled": "true" }).text("—————————————"));
                //sel.append($("<option>").attr('value', "OT").text("Other Territory"));

                callback(sel, data);

            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("error - fetching states");
            }
        });
    }

    
}